:root {
    --color-primary:#2584ff; 
    --color-secondary:#00d9ff; 
    --color-accent:#ff3400; 
    --color-headings:#1b0760; 
    --color-body:#918ca4; 
    --color-border:#ccc; 
    --border-radius:30px; 
    --color--body--darker:#5c5577;
    --color--back:#c1adad; 
}
*,*::after, *::before{
    box-sizing:border-box; 
}

::selection{
    background:var(--color-primary); 
    color:white; 

}

html{
    font-size:62.5%; 
}

body{
    font-family: Inter, Arial,Arial, Helvetica, sans-serif;
    color:var(--color-body);
    font-size:2.4rem;
    line-height: 1.5;
    margin-bottom: 0rem;
    background:#000;
    background-repeat: no-repeat; /* Evita que la imagen se repita */
    background-size: cover; /* Cubre todo el fondo */
    min-height:100vh; 

}



h1,h2,h3{
    color:var(--color-headings); 
    margin-bottom:1rem;   
    line-height:1.1; 
}

h1{
    font-size:7rem; 
}

h2{
    font-size:4rem; 
}

h3{
    font-size:3rem; 
    font-weight:500; 
     
}

p{
    margin-top:0rem; 
}

@media screen and (min-width:1024px){
    body{
        font-size:1.8rem; 
    }
    h1{
        font-size:8rem; 
    }
    h2{
        font-size:4rem; 
    }
    h3{
        font-size:2.4rem; 
    }

}

a{
    text-decoration:none; 
}
.main{
    background:#000;
}


.link-arrow{
    color:var(--color--back);
    text-transform: uppercase;
    font-size:2rem; 
    font-weight:bold; 
}

.link-arrow::after{
    content: '-->';
    margin-left:5px; 
    transition:margin 0.15s; 
}

.link-arrow:hover::after{
    margin-left:10px;
}

@media screen and (min-widht:1024px){
    .link-arrow{
        font-size:1.5rem; 
    }
}

.badge{
    border-radius:20px; 
    padding:0.5rem 2rem; 
    font-weight:600; 
    white-space:nowrap; 
    font-size:2rem; 
}

.badge--primary{
    
    color:white; 
}

.badge--secondary{
    
    color:white;
}

.badge--small{
    font-size:1.6rem; 
    padding:0.5rem 1.5rem; 
}

@media screen and (min-widht: 1024px){
    .badge{
        font-size:1.5rem; 
    }
    .badge--small{
        font-size:1.2rem; 
    }
}

.list{
    list-style:none; 
    padding-left:0;
    
}

.list--inline .list__item{
    display:inline-block; 
    margin-right:2rem; 
}

.list--tick{
    list-style-image: url(../images1/tick.svg);
    padding-left:3rem; 
    color:var(--color-headings);

}.poemass-22{
    border:10px solid #fff !important;
}.options{
    margin-top:-3rem;
    justify-content:center; 
    overflow: hidden !important;
}

.object__text,.about{
    font-size:2.4rem; 
}
.list-tick .list__item{
    padding-left:0.5rem; 
    margin-bottom:1rem; 
}

@media screen and (min-width:1024px){
    .list--tick .list__item{
        padding-left:0; 
    }
}
.logo{
    width:20rem;
}
.icon{
    width:5rem; 
    height:40px;
}

.fa-brands{
    font-size:6rem; 
    color:#fff; 
}
.fa-brands:hover{
    font-size:7rem; 
    color:#bdbdbd; 
}

.icon--primary{
    fill:var(--color-primary);
}

.icon--small{
    width:30px; 
    height:30px; 
}

.icon--white{
    fill:white;
}

.icon-container{
    margin-top:-10rem;
    width:64px; 
    height:64px; 
    border-radius:100%; 
    display:inline-flex;
    justify-content:center; 
    align-items:center; 
    gap:4rem; 
    
}

.icon-container--accent{
    background:var(--color-accent);
}

.btn{
    font-size:1.8rem; 
    font-weight:600;
    text-transform: uppercase;
    padding:2rem 4vw; 
    border:0; 
    border-radius:40px; 
    cursor: pointer;
    white-space: nowrap;
    text-align:center; 
    margin:1rem 0; 
    outline:0; 
}

.btn .icon{
    width:2rem; 
    height:2rem; 
    margin-right:1rem; 
    vertical-align:middle; 
}

.btn--primary{
    border:2px solid var(--color-primary);
    
    color:var(--color-primary); 
}

.btn--primary:hover{
    background:var(--color-primary);
    color:white;
}



.btn--secondary,.card__git{
    border:2px solid #ffffff;
    background:transparent;
    color:#ffffff; 
}
.btn--secondary:hover,.card__git:hover{
    background:transparent;
    color:#d3d2d2;
    border-color:#d3d2d2 ;
}
.btn--accent{
    background:#cd09c0;
    color:white; 
}
.btn--accent:hover{
    background:#ff6a00;
}

.btn--stretched{
    padding-left:6rem; 
    padding-right:6rem; 

}

.btn--block,.card__git{
    width:100%; 
    display: inline-block;
}
.card__git{
    border:none; 
}


@media screen and (min-width:1024px){
    .btn{
        font-size:1.5rem; 
    }.card__git{
        border:none; 
    }
}   

.input{
    border-radius:var(--border-radius); 
    border:1px solid var(--color-border); 
    color:var(--color-accent);
    font-size:2rem; 
    outline: 0;
    padding:1.5rem 3.5rem; 
    
}

::placeholder{
    color: #cdcbd7;
}

.input-group{
    border: 1px solid var(--color-border); 
    border-radius:var(--border-radius);
    display:flex; 
}

.input-group .input{
    border:0; 
    flex-grow:1; 
    padding:1.5rem 2rem; 
    width:0; 
   
}

.input-group .btn{
    margin:4px; 
}
@media screen and (min-width:1024px){
    .input{
        font-size:1.5rem; 
    }
}

.card{
    border-radius:7px; 
    box-shadow: 0 0 1px 1px #ffffff;
    overflow:hidden;
    
}



.card__header, .card__body{
    padding:2rem 3rem;
}

.card--primary .card__header{
    background:var(--color-primary);
    color:white;

}

.card--secondary .card__header{
    
    color:#fff;

}

.card--secondary .badge--secondary{
    border:2px solid #fff;

}



.card--primary .badge--primary{
    background:#126de5; 

}
.card__body >img{
    width:100%; 

}

.plan{
    transition:transform .2s ease-out; 
}

.plan__name{
    color:white; 
    margin:0; 
    font-weight:500; 
    font-size:2.4rem; 
}

.plan__price{
    font-size:6rem; 
    margin-bottom:0; 
    display:inline-block;
    line-height:1;
   
}


.plan__billing-cycle{
    display:inline-block;
    font-size:2.4rem; 
    font-weight:300; 
    opacity:.8;
    margin-right:1rem; 
     
    
}

.plan__description{
    font-size:2rem; 
    font-weight:300; 
    letter-spacing:1px;
    display:block;  
}

.plan .list__item{
    margin-bottom:2rem; 

}

.plan--popular{
    transform:scale(1.035); 
}


.plan--popular .card__header{
    position:relative; 
}

.plan--popular .card__header::before{
    width:40px; 
    display:inline-block; 
    position:absolute; 
    top:-6px; 
    right:5%; 
}

.plan:hover{
    transform:scale(1.075); 
}

.plan--popular:hover{
    transform:scale(1.1); 
}




@media screen and (min-width:1024px){
    .plan__price{
        font-size:5rem; 
    }
    .plan__billing-cycle{
        font-size:1.6rem;
    }
    .plan__description{
        font-size:1.7rem; 
    }
    .plan--europe,.plan--viruses{
        font-size:4rem; 
        margin-bottom:1rem;
       
    }
    
    
}

@media screen and (max-width:400px){
    .plan--viruses{
        font-size:2.9rem; 
        margin-bottom:1rem;
       
    }
}
.feature__images-container {
    display: flex;
    flex-direction: column;
    padding:1rem; 
  }



.media{
    display:flex; 
    margin-bottom:4rem; 

}

.media__title{
    margin-top:0; 
}

.media__body{
    margin:0 2rem; 
}

.media__image{
    margin-top:0.5rem; 
}

.quote{
    font-size:3rem; 
    font-style:italic; 
    color:var(--color--body--darker);
}



.quote__text::before{
    content:open-quote; 
}

.quote__text::after{
    content:close-quote; 
}

.quote__author{
    font-weight:500; 
    font-style:normal;
    margin-bottom:0; 

}
.quote__company{
    color:var(--color-headings); 
    opacity:.4; 
    font-size:2rem; 
    font-style:normal; 
}

.quote__line{
    position:relative; 
    bottom:10px; 
}

@media screen and (min-wdith:1024px){
    .quote{
        font-size:2rem; 
    }
    .quote__author{
        font-size:2.4rem; 
    }
    .quote__company{
        font-size:1.6rem; 
    }
}

.grid{
    display:grid; 

}

@media screen and  (min-width:768px){
    .grid--1x2{
        grid-template-columns:1fr 1fr; 
    }
}

@media screen and (min-width:1024px){
    .grid--1x3{
        grid-template-columns:1fr 1fr 1fr; 
    }
}


.testimonial{
    padding:3rem; 
    border:solid 2px rgb(236, 235, 235); 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    
}

.testimonial__image{
    position:relative; 


}

.testimonial__image > img{
    width:100%; 
}

.testimonial__image > .icon-container{
    position:absolute; 
    top:3rem; 
    right:-32px; 
}

@media screen and (min-width:768px){
    .testimonial .quote, 
    .testimonial .quote__author{
        font-size:2.4rem; 
    }

    .testimonial .quote{
        margin-left:6rem
    }
}


.callout{
    padding:4rem; 
    border-radius:5px; 

}

.callout--primary{
    background:var(--color-primary);
    color:white; 
}

.callout__heading{
    margin-top:0;
    color:#fff; 
    font-size:3rem; 
}

.callout .btn{
    justify-self: center;
    align-self:center; 
}

.callout__content{
    text-align:center; 
}

@media screen and (min-width:768px){
    .callout .grid--1x2{
        grid-template-columns:1fr auto; 
    
    }


    .callout__content{
        text-align:left; 
    }

    .callout .btn{
        justify-self: start;
        margin:0 2rem;  
    }
}

.collapsible__header{
    display:flex;
    justify-content: space-between; 
}

.collapsible__heading{
    margin-top: 0;
    font-size:3rem; 
}

.collapsible__chevron{
    transform:rotate(-90deg); 
    transition:transform 0.3s;
}

.collapsible__content{
    max-height:0; 
    overflow:hidden; 
    opacity:0; 
    transition:all 0.3s;
}



.collapsible--expanded .collapsible__chevron{
    transform:rotate(0); 
}

.collapsible--expanded .collapsible__content{
    display:block; 
    max-height:100vh; 
    opacity:1; 
}

.block{
    --padding-vertical:9rem; 
    padding:var(--padding-vertical) 2rem; 
    
    
}



.block__heading{
    margin-top:0; 
    margin-bottom:5rem; 
    color:var(--color-headings);
    
}

.block--dark{
    color:#7b858b;
}

.block--dark h1,.block--dark h2,.block--dark h3{
    color:#fff; 
}

.block--dark .block__heading{
    color:#fff; 
}

.block--skewed-right{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
    padding-bottom:calc(var(--padding-vertical) + 4rem); 
}

.block--skewed-left{
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
    padding-bottom:calc(var(--padding-vertical) + 4rem); 
}

.block__header{
    text-align:center;
    margin-bottom:0; 
    margin-top:3rem;
    padding:2rem 0; 
}

.aboutme{
    margin-top:-10rem; 
}
.aboutme__header{
    color:#fff;
    
}
.aboutme__content{
    color:#fff; 
}


.block__header .header--about{
    color:#000; 
}

.container{
    max-width:1140px; 
    margin: 0 auto; 
    overflow:hidden; 
   
}


.nav{
    display:flex;
    justify-content:space-between; 
    flex-wrap:wrap; 
    padding:0 1rem; 
    align-items:center; 
}

.nav__list{
    width:100%; 
    margin:0; 

}

.nav__item{
    padding:0.5em 2rem; 
    border-bottom:1px solid #fff; 

}

.nav__item >a{
    color:#fff; 
    transition:color .3s; 
}

.nav__item >a:hover{
    color:#4f4c4c; 
}

.nav__toggler{
   
   
   cursor:pointer; 
}

.nav.collapsible--expanded .nav__toggler{
    opacity:1; 
    
} 

.nav__brand{
     
    transform:translateY(5px); 
}

@media screen and (min-width:768px){
    .nav__toggler{
        display:none; 
    }
    .nav__list {
        width:auto; 
        display:flex;
        
        max-height:100%; 
        opacity:1;
        

    }
    .nav__item{
        border:0; 
        
    }.collapsible{
        pointer-events: none;
       
        
    }.collapsible a{
        pointer-events:all;     
    }
    
}


.hero__tagline{
    font-size:2rem; 
    color:rgb(255, 255, 255);
    letter-spacing:1px; 
    margin:2rem 0 5rem; 
}


.hero__image{
    width:100%; 
    
}

@media screen and (min-width:400px){
    .hero{
    height: 100vh; /* Establece la altura al 100% del viewport */
    display: flex; /* Usa flexbox para alinear contenido verticalmente */
    align-items: center; /* Centra verticalmente el contenido */
    margin-top: 0; /* Elimina cualquier margen superior */
    margin-bottom: 0; /* Elimina cualquier margen inferior */
    }

    .hero__content{
        text-align:center; 
        padding: 0 2rem;
    }
}

.block__domain .input-group{
    box-shadow: 0 0 30px 20px #e6ebee; 
    border:0; 
    margin:4rem auto; 
    max-width:670px; 
}

.block-domain__prices{
    color:var(--color-headings);
    display:grid; 
    grid-template-columns:repeat(2,1fr); 
    grid-template-rows:repeat(2,6rem);
    font-size:2rem; 
    font-weight:600; 
    justify-items:center; 
    max-width:700px;
    margin:0 auto; 
}

@media screen and (min-width:768px){
    .block-domain__prices{
        grid-template-columns:repeat(auto-fit,minmax(10rem,1fr));
    }
}

.block-plans .grid{
    gap:8rem 4rem; 
}

.block-plans .card{
    max-width:500px; 
    margin:0 auto; 
}

.project__heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; /* Para centrar el texto horizontalmente */
    margin-top:-5rem;
    margin-bottom:1rem; 
    color:#fff; 
    
}

.project__heading >h2{
    font-size:7rem; 
    color:#fff; 

}





.grid--2 {
    margin-top:3rem; 
   
}



.grid--2 .card--primary{
    margin-top:5rem; 
}
@media screen and (max-width:600px){
    .grid--1x3,
  .grid--2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem; 
  }
}

.feature__heading{
    margin:1rem 0; 
    
}

.feature{
    gap:4rem 2rem;
    margin:12rem 0rem;  

}

.feature__image{
    width:55%; 
    margin-bottom:2rem; 
    border:5px solid #fff;

}

.feature:first-of-type{
    margin-top:0rem; 
}

@media screen and (min-width : 768px){
    .feature:nth-of-type(even) .feature__content{
        order:2; 
    }
}

.block--showcase__image{
    width:100%; 
}

@media screen and (min-width:768px){
    .block--showcase .grid{
        grid-template-columns: 50% 50%;
    }
    .block--showcase__image{
        width:auto; 
        max-width:700px; 
        justify-self: end;
    }
}

.footer{
    background:#232323; 
}

.footer a{
    color:#777;
    transition:color .3s;
}
.footer a:hover{
    color:#fff; 

}


.footer__section{
    padding:2rem; 
    border-bottom:1px solid #393939; 
}

.footer__section .list{
    margin:0; 
}

.footer__heading{
    text-transform: uppercase;
    font-weight:600; 
}

.footer__brand{
    margin-top:5rem; 
    text-align:center; 
}

.footer__brand img{
    width:100%; 
    max-width:230px; 
}


.footer__copyright{
    font-size:2.1rem; 
    color:#fff; 
    opacity:.3; 
}

@media screen and (min-width:768px){
    .footer__sections{
        grid-template-columns: repeat(auto-fit,minmax(10rem,1fr));
    }
    .footer .collapsible__chevron{
        display:none; 
    }
    .footer .collapsible__content{
        opacity:1; 
        max-height:100%; 
    }
    .footer__brand{
        order:-1; 
        margin-top:1rem;
    }

    .footer__copyright{
        font-size:1.5rem;
    }

    .footer__section{
        border:0; 
    }

    .footer__heading{
        font-size:1.6rem; 
    }
}
.block__contactme{
   
    display: flex;
    justify-content: right;
    align-items: right;
    height: 100vh; /* Esto asegura que ocupe toda la altura de la ventana */

}

form{
    width: 75%;
    padding: 1rem;
    margin: 0 auto; /* Esto centra horizontalmente el formulario */
    display: flex; /* Aplicar flexbox para alinear los elementos internos */
    flex-direction: column; /* Apilar los elementos verticalmente */
    justify-content: center;
    align-items: center;
    margin-top:-10rem; 
    color:#000;
    
}

#emailInput,#nameInput,#messageInput {
    border:2px solid #fff;
    background-color: 
    transparent; /* Cambia el color de fondo del campo de correo electrónico a rojo */
    color: rgb(0, 0, 0); /* Cambia el color del texto a blanco para que se vea mejor sobre el fondo rojo */
}
#nameInput::placeholder,
#emailInput::placeholder,
#messageInput::placeholder {
    color: rgb(255, 255, 255);
    font-family: Inter, Arial,Arial, Helvetica, sans-serif;
   line-height:1.1;
    /* Cambia el color del texto predeterminado a negro */
}
#nameInput:focus,
#emailInput:focus,
#messageInput:focus {
     /* Cambia el color de fondo cuando el campo está enfocado */
    border-color: #d5d3d3;
    
     /* Cambia el color del borde cuando el campo está enfocado */
}
#nameInput:focus::placeholder,
#emailInput:focus::placeholder,
#messageInput:focus::placeholder {
    color: #d5d3d3; /* Cambia el color del texto predeterminado cuando el campo está enfocado */
}

input[type="text"],
input[type="email"],
textarea {
    color: rgb(0, 0, 0); /* Establecer el color del texto en blanco */
    border-color:#000;
    border:2px solid #fff; 
}
#nameInput,
#emailInput,
#messageInput {
  color: #fff; /* Cambia el color del texto a rojo (#ff0000) */
}

.link__contactme{
    display: flex; /* Aplicar flexbox para alinear los elementos internos */
    justify-content: center;
    align-items: center;
    color:var(--color--back);
    text-transform: uppercase;
    font-size:1.5rem; 
    font-weight:bold; 
}
.block__header__contactme{
    margin-bottom:-3rem; 
}

.btn__contactme{
    padding:0 4vw; 
}

.no-touch .btn__contactme:hover{
    background:transparent;
    color:rgb(189, 187, 187);
}


.res .nav.collapsible--expanded{
    font-size:2.4rem; 
    line-height: 1;
}.res .collapsible{
    font-size:1.8rem; 
    line-height: 1.5;

}
@media screen and (min-width:768px){
    form{
        width:35%; 
    }
    .link__contactme{
        font-size:1.8rem; 
    }
    
}
.heading__contactme, .heading__messageme {
   font-family: Inter, Arial,Arial, Helvetica, sans-serif;
   line-height:1.1;
   font-size:4rem; 
   font-weight:bold; 
   color:#fff; 
}
.message{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh; /* Para centrar verticalmente */
    margin-bottom:11rem; 
    margin-top:-10rem; 
    

}
.heading__messageme{
    color:#fff;
}




.resume__container{ 
    margin-top:-20rem; 
    width:50%; 
    height:65%;
    margin-bottom:7rem; 
}

.resume{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh; /* Para centrar verticalmente */
      
}
.object__text{
    font-size:1.4rem; 
    color:#fff;
    font-family: Inter, Arial,Arial, Helvetica, sans-serif;
   
}
.object__text a{
    color:#9b9898; 
}
.object__text a:hover{
    color:#fff; 
}
.oject__text,.about {
    color:#9b9898; 
    font-size:2.4rem; 
}
.about:hover{
    color:#fff; 

}

.object__resume{
    visibility:hidden; 
}
@media screen and (min-width:768px){
    .object__resume{
        width:100%; 
        height:200%; 
        visibility: visible;
    }
    
}

.container__contactme{
    padding:0;
    margin-top:2rem; 
    margin-bottom:2rem; 
    
}

.icon--contactme{
    justify-content: center;
    align-items: center;
    display:flex; 
    width:100%; 
}
.icon--mail{
    margin-top:1rem; 
}
.contact__info{
    font-size:1.8rem; 
    margin-top:1rem; 
    font-family: Inter, Arial,Arial, Helvetica, sans-serif;

    color:#fff; 
    justify-content: center;
    align-items: center;
    display:flex;
}


.contact{
   margin-top:0; 
}

.contact__formulario{
    color:#fff;
     
   
}
@media screen and (max-width:700px){
    
    .feature__image{

        
        width:75%; 
        max-width:600px; 

    }.object__text{
        font-size:1.8rem; 
    }
}

    .feature__content{
        order:1; 
    }
    .grid--1x2 .contactgrid{
        display:grid; 
        grid-template-columns:auto;
    }
    .grid--1x2{
        grid-template-columns:1fr 1fr; 
    }
    


/* Barra de desplazamiento */
::-webkit-scrollbar {
    width: 7px; /* Ancho de la barra */

    color:#000; 
  }
  
  /* Parte de agarre de la barra de desplazamiento */
  ::-webkit-scrollbar-thumb {
    background-color: #000; /* Color de fondo del agarre */
    border-radius: 20px; /* Borde redondeado del agarre */
    background-clip: content-box;

  }
  
  /* Fondo de la barra de desplazamiento */
  ::-webkit-scrollbar-track {
    background-color: #fff; /* Color de fondo de la barra */
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #000;
  }

  .bullet-point{
    margin-right:10px; 
  }
 

  
 .resres {
    display: grid;
    grid-template-columns: 1;
    grid-auto-rows: minmax(100px, auto);
    
}
.image{
    padding-left:2rem; 
    margin-bottom:5rem; 
}

@media screen and (min-width: 1025px) {
    .resres {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        
    }
    .text:nth-of-type() {
        grid-column: 2;
        grid-row: 2;
    }
}
@media screen and (max-width:768px){
    .about{
        font-size:1.8rem; 
    }
}

